(function () {
  'use strict';

  class SchoolsRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    schools() {
      return this.$resource(`${this.env.api}/schools`);
    }

    school() {
      return this.$resource(`${this.env.api}/schools/:id`, {id: '@school.id'}, {update: {method: 'PUT'}});
    }

    lomMetadata() {
      return this.$resource(`${this.env.api}/schools/:id/lom-metadata`, {id: '@id'});
    }

    mySchools() {
      return this.$resource(`${this.env.api}/schools/my-schools`);
    }
  }

  /**
   * @ngdoc service
   * @name components.service:SchoolsRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('SchoolsRest', SchoolsRest);
}());
